import { defineNuxtPlugin } from '#app'
import { Capacitor } from '@capacitor/core'
import dayjs from 'dayjs'

export default defineNuxtPlugin(() => {
	const utils = {
		removeSpecialChars(str: string): string {
			return str.replace(/[^a-zA-Z0-9 ]/g, '')
		},
		sanitizeNumber(str: string): number {
			return Number(str.replace(/[^\d,]/g, '').replace(',', '.'))
		},
		formatCurrency(value: number): string {
			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}).format(value)
		},
		formatPoints(value: number): string {
			if (value >= 1_000_000_000) {
				return (value / 1_000_000_000).toFixed(value % 1_000_000_000 === 0 ? 0 : 1) + 'B'
			} else if (value >= 1_000_000) {
				return (value / 1_000_000).toFixed(value % 1_000_000 === 0 ? 0 : 1) + 'M'
			} else {
				return new Intl.NumberFormat('pt-BR').format(value)
			}
		},
		formatDate(date: string | Date): any {
			return dayjs(date).format('DD/MM/YYYY')
		},
		validateCPF(cpf: string): boolean {
			cpf = cpf.replace(/\D/g, '')

			if (cpf.length !== 11) {
				return false
			}

			if (/^(\d)\1{10}$/.test(cpf)) {
				return false
			}

			let sum = 0
			for (let i = 0; i < 9; i++) {
				sum += parseInt(cpf.charAt(i)) * (10 - i)
			}
			let remainder = 11 - (sum % 11)
			let digit1 = remainder > 9 ? 0 : remainder

			sum = 0
			for (let i = 0; i < 10; i++) {
				sum += parseInt(cpf.charAt(i)) * (11 - i)
			}
			remainder = 11 - (sum % 11)
			let digit2 = remainder > 9 ? 0 : remainder

			return parseInt(cpf.charAt(9)) === digit1 && parseInt(cpf.charAt(10)) === digit2
		},
		async share(content: { title: string; text: string; url: string }) {
			const { Capacitor } = await import('@capacitor/core')

			if (Capacitor.isNativePlatform()) {
				const { Share } = await import('@capacitor/share')
				const canShare = await Share.canShare()
				if (!canShare) return
				await Share.share(content)
			} else {
				if (navigator && navigator.share) {
					navigator.share(content)
				} else {
					navigator.clipboard.writeText(content.url)
				}
			}
		},
		async hasAllowedAppFilesPermission() {
			const { Capacitor } = await import('@capacitor/core')

			if (Capacitor.isNativePlatform()) {
				const { Filesystem } = await import('@capacitor/filesystem')
				const permissions = await Filesystem.checkPermissions()

				if (permissions.publicStorage === 'granted') {
					return true
				}

				const request = await Filesystem.requestPermissions()

				return request.publicStorage === 'granted'
			}

			return true
		},
		async hasAllowedAppCameraPermission() {
			const { Capacitor } = await import('@capacitor/core')

			if (Capacitor.isNativePlatform()) {
				const { Camera } = await import('@capacitor/camera')

				const permissions = await Camera.checkPermissions()

				if (permissions.camera === 'granted') {
					return true
				}

				const request = await Camera.requestPermissions()

				return request.camera === 'granted'
			}

			return true
		},

		async downloadFile(fileUrl: string | Blob, fileName: string, directory: string = 'Documents') {
			const blob = typeof fileUrl === 'string' ? await fetch(fileUrl).then(r => r.blob()) : fileUrl

			if (Capacitor.getPlatform() === 'web') {
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = fileName
				link.click()
				link.remove()
			} else {
				const { Filesystem, Directory } = await import('@capacitor/filesystem')
				const reader = new FileReader()

				reader.readAsDataURL(blob)

				await new Promise<void>((resolve, reject) => {
					reader.onloadend = () => {
						console.log('Downloading...', reader.result)

						Filesystem.writeFile({
							path: `iloyal-${fileName}`,
							data: reader.result as string,
							directory: Directory[directory as keyof typeof Directory],
							recursive: true,
						})
							.then(() => resolve())
							.catch(reject)
					}
				})

				console.log('Download complete.')
			}
		},
	}

	return {
		provide: {
			utils,
		},
	}
})
