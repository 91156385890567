import { default as _91_46_46_46all_93xu44z2gGgNMeta } from "/usr/src/app/pages/[...all].vue?macro=true";
import { default as checkout2tYiGAKKaEMeta } from "/usr/src/app/pages/account/checkout.vue?macro=true";
import { default as complete_45profilez8rf4UKUeYMeta } from "/usr/src/app/pages/account/complete-profile.vue?macro=true";
import { default as deleteXrIYUm36UPMeta } from "/usr/src/app/pages/account/delete.vue?macro=true";
import { default as index6LTnG94DlyMeta } from "/usr/src/app/pages/account/index.vue?macro=true";
import { default as menuh57gm6t9LlMeta } from "/usr/src/app/pages/account/menu.vue?macro=true";
import { default as notificationsCp6yLRkO4JMeta } from "/usr/src/app/pages/account/notifications.vue?macro=true";
import { default as settings4DJbRzkKKtMeta } from "/usr/src/app/pages/account/settings.vue?macro=true";
import { default as subscriptionFTjCnOY96tMeta } from "/usr/src/app/pages/account/subscription.vue?macro=true";
import { default as _91transactionId_93SZ1bbcdZnpMeta } from "/usr/src/app/pages/account/transactions/[transactionId].vue?macro=true";
import { default as walletx02Cdr4nYkMeta } from "/usr/src/app/pages/account/wallet.vue?macro=true";
import { default as _91transactionId_93sA9pAfUKGTMeta } from "/usr/src/app/pages/applications/[applicationId]/admin/[transactionId].vue?macro=true";
import { default as indexV42Cc8QHvbMeta } from "/usr/src/app/pages/applications/[applicationId]/admin/customers/index.vue?macro=true";
import { default as indexIm2UF2BwtKMeta } from "/usr/src/app/pages/applications/[applicationId]/admin/index.vue?macro=true";
import { default as _91userId_93j7GWMraCM5Meta } from "/usr/src/app/pages/applications/[applicationId]/admin/sellers/[userId].vue?macro=true";
import { default as indexHzCsku11niMeta } from "/usr/src/app/pages/applications/[applicationId]/admin/sellers/index.vue?macro=true";
import { default as index6VofIfsn8lMeta } from "/usr/src/app/pages/applications/[applicationId]/edit/index.vue?macro=true";
import { default as _91userId_934FeUGbPpzXMeta } from "/usr/src/app/pages/applications/[applicationId]/gift/[userId].vue?macro=true";
import { default as index4ZWejAVb4pMeta } from "/usr/src/app/pages/applications/[applicationId]/index.vue?macro=true";
import { default as indexl7KqQdp49zMeta } from "/usr/src/app/pages/applications/[applicationId]/products/[productId]/edit/index.vue?macro=true";
import { default as createkBkPbeaNKoMeta } from "/usr/src/app/pages/applications/[applicationId]/products/create.vue?macro=true";
import { default as createkUAQj2GE81Meta } from "/usr/src/app/pages/applications/create.vue?macro=true";
import { default as index2rWGvHshO3Meta } from "/usr/src/app/pages/applications/index.vue?macro=true";
import { default as ownedDwTcXcSjI9Meta } from "/usr/src/app/pages/applications/owned.vue?macro=true";
import { default as _91slug_93VJ8Cr2kZBxMeta } from "/usr/src/app/pages/c/[slug].vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as privacy_45policy1IN0o4tDn5Meta } from "/usr/src/app/pages/privacy-policy.vue?macro=true";
import { default as terms_45of_45usekooS9dlcBMMeta } from "/usr/src/app/pages/terms-of-use.vue?macro=true";
import { default as _91userId_93fm6rHu13AzMeta } from "/usr/src/app/pages/w/[userId].vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all(.*)*",
    meta: _91_46_46_46all_93xu44z2gGgNMeta || {},
    component: () => import("/usr/src/app/pages/[...all].vue")
  },
  {
    name: "account-checkout",
    path: "/account/checkout",
    meta: checkout2tYiGAKKaEMeta || {},
    component: () => import("/usr/src/app/pages/account/checkout.vue")
  },
  {
    name: "account-complete-profile",
    path: "/account/complete-profile",
    meta: complete_45profilez8rf4UKUeYMeta || {},
    component: () => import("/usr/src/app/pages/account/complete-profile.vue")
  },
  {
    name: "account-delete",
    path: "/account/delete",
    meta: deleteXrIYUm36UPMeta || {},
    component: () => import("/usr/src/app/pages/account/delete.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue")
  },
  {
    name: "account-menu",
    path: "/account/menu",
    meta: menuh57gm6t9LlMeta || {},
    component: () => import("/usr/src/app/pages/account/menu.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: notificationsCp6yLRkO4JMeta || {},
    component: () => import("/usr/src/app/pages/account/notifications.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settings4DJbRzkKKtMeta || {},
    component: () => import("/usr/src/app/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionFTjCnOY96tMeta || {},
    component: () => import("/usr/src/app/pages/account/subscription.vue")
  },
  {
    name: "account-transactions-transactionId",
    path: "/account/transactions/:transactionId()",
    meta: _91transactionId_93SZ1bbcdZnpMeta || {},
    component: () => import("/usr/src/app/pages/account/transactions/[transactionId].vue")
  },
  {
    name: "account-wallet",
    path: "/account/wallet",
    meta: walletx02Cdr4nYkMeta || {},
    component: () => import("/usr/src/app/pages/account/wallet.vue")
  },
  {
    name: "applications-applicationId-admin-transactionId",
    path: "/applications/:applicationId()/admin/:transactionId()",
    meta: _91transactionId_93sA9pAfUKGTMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/admin/[transactionId].vue")
  },
  {
    name: "applications-applicationId-admin-customers",
    path: "/applications/:applicationId()/admin/customers",
    meta: indexV42Cc8QHvbMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/admin/customers/index.vue")
  },
  {
    name: "applications-applicationId-admin",
    path: "/applications/:applicationId()/admin",
    meta: indexIm2UF2BwtKMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/admin/index.vue")
  },
  {
    name: "applications-applicationId-admin-sellers-userId",
    path: "/applications/:applicationId()/admin/sellers/:userId()",
    meta: _91userId_93j7GWMraCM5Meta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/admin/sellers/[userId].vue")
  },
  {
    name: "applications-applicationId-admin-sellers",
    path: "/applications/:applicationId()/admin/sellers",
    meta: indexHzCsku11niMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/admin/sellers/index.vue")
  },
  {
    name: "applications-applicationId-edit",
    path: "/applications/:applicationId()/edit",
    meta: index6VofIfsn8lMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/edit/index.vue")
  },
  {
    name: "applications-applicationId-gift-userId",
    path: "/applications/:applicationId()/gift/:userId()",
    meta: _91userId_934FeUGbPpzXMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/gift/[userId].vue")
  },
  {
    name: "applications-applicationId",
    path: "/applications/:applicationId()",
    meta: index4ZWejAVb4pMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/index.vue")
  },
  {
    name: "applications-applicationId-products-productId-edit",
    path: "/applications/:applicationId()/products/:productId()/edit",
    meta: indexl7KqQdp49zMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/products/[productId]/edit/index.vue")
  },
  {
    name: "applications-applicationId-products-create",
    path: "/applications/:applicationId()/products/create",
    meta: createkBkPbeaNKoMeta || {},
    component: () => import("/usr/src/app/pages/applications/[applicationId]/products/create.vue")
  },
  {
    name: "applications-create",
    path: "/applications/create",
    meta: createkUAQj2GE81Meta || {},
    component: () => import("/usr/src/app/pages/applications/create.vue")
  },
  {
    name: "applications",
    path: "/applications",
    meta: index2rWGvHshO3Meta || {},
    component: () => import("/usr/src/app/pages/applications/index.vue")
  },
  {
    name: "applications-owned",
    path: "/applications/owned",
    meta: ownedDwTcXcSjI9Meta || {},
    component: () => import("/usr/src/app/pages/applications/owned.vue")
  },
  {
    name: "c-slug",
    path: "/c/:slug()",
    component: () => import("/usr/src/app/pages/c/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/usr/src/app/pages/terms-of-use.vue")
  },
  {
    name: "w-userId",
    path: "/w/:userId()",
    meta: _91userId_93fm6rHu13AzMeta || {},
    component: () => import("/usr/src/app/pages/w/[userId].vue")
  }
]