import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "authenticate-admin": () => import("/usr/src/app/middleware/authenticate-admin.ts"),
  "authenticate-seller": () => import("/usr/src/app/middleware/authenticate-seller.ts"),
  "authenticate-user": () => import("/usr/src/app/middleware/authenticate-user.ts"),
  "authorize-guest": () => import("/usr/src/app/middleware/authorize-guest.ts"),
  "authorize-user": () => import("/usr/src/app/middleware/authorize-user.ts")
}